import React from "react";
import { Container } from "react-bootstrap";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
const HeaderBottom = ({ pathName }: any) => {
  return (
    <div className="page-banner-area">
      <Container>
        <div style={{ color: "#0F172A" }} className="text-center pt-4 ">
          <h1
            style={{ fontWeight: "bold", color: "black" }}
            className="opacity-300"
          >
            {pathName}
          </h1>
          <p className="fw-bold opacity-100 z-11">
            Home <MdOutlineKeyboardArrowRight /> {pathName}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default HeaderBottom;
